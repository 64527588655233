import React from 'react'
import { graphql } from 'gatsby'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import TestimonialIndex from '../components/views/CaseStudyTestimonialIndex/TestimonialIndex'

const TestimonialIndexTemplate = ({ data, pageContext, location }) => {
  return (
    <>
      <Seo
        title='Testimonials'
        description={data.page?.data?.body[0]?.primary?.hero_subtitle?.text || 'Read our graduates stories and see what our hiring partners say about us'}
        slug={location.pathname}
      />
      <TestimonialIndex
        data={data}
        pageContext={pageContext}
      />
    </>
  )
}

export default TestimonialIndexTemplate

export const PageQuery = graphql`
  query TestimonialIndexTemplateQuery ($skip: Int!, $limit: Int!) {
    page: prismicPage(
      uid: {
        eq: "testimonials"
      }
    ) {
      uid
      data {
        title {
          text
        }
      }
      ...Hero
      ...PreFooter
    }
    testimonials: allPrismicTestimonial (
      filter: {
        data: {
          featured: {
            eq: false
          }
        }
      },
      sort: {
        order: DESC,
        fields: data___published
      },
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...Testimonial
      }
      totalCount
    }
    featuredTestimonials: allPrismicTestimonial (
      filter: {
        data: {
          featured: {
            eq: true
          }
        }
      },
      limit: 1,
      skip: $skip,
      sort: {
        order: DESC,
        fields: data___published
      }
    ) {
      nodes {
        ...Testimonial
      }
    }
  }
`
