import React from 'react'
import PropTypes from 'prop-types'

// Generic
import ArticleGridFeaturedItem from '../../shared/ArticleGrid/ArticleGridFeaturedItem'
import Embed from '../../shared/Embed/Embed'

// Utils
import getTestimonialIntro from '../../../utils/getTestimonialIntro'
import testimonialDecorator from '../../../utils/testimonialDecorator'

// Local
import CaseStudyTestimonialIndex from './CaseStudyTestimonialIndex'

const TestimonialIndex = (props) => {
  const { data, pageContext } = props

  const testimonials = testimonialDecorator(data.testimonials.nodes)

  return (
    <CaseStudyTestimonialIndex
      slices={data.page.data.body}
      items={testimonials}
      type='testimonial'
      pageContext={pageContext}
      totalCount={data.testimonials.totalCount}
      featuredItem={
        <>
          {data.featuredTestimonials.nodes.length > 0 && (
            <ArticleGridFeaturedItem
              body={getTestimonialIntro(data.featuredTestimonials.nodes[0].data)}
              category='Featured'
              date={data.featuredTestimonials.nodes[0].data.published}
              media={(
                <Embed
                  embed={data.featuredTestimonials.nodes[0].data.embed}
                  title={`${data.featuredTestimonials.nodes[0].data.name.text}'s story`}
                  className='h-full'
                  imageClassName='h-full object-cover'
                />
              )}
              title={data.featuredTestimonials.nodes[0].data.title.text}
              url={data.featuredTestimonials.nodes[0].url}
            />
          )}
        </>
      }
    />
  )
}

TestimonialIndex.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default TestimonialIndex
